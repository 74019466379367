import React, {useEffect, useState} from "react";

export default function BusinessPlanDurationTabPicker({duration, setDuration})
{
    const durations = [
        {
            label:'Monthly',
            value:'monthly',
        },
        {
            label:'Yearly',
            value:'yearly',
        },
    ];

    return <div
            style={{
                borderRadius: '30px',
                boxShadow: '0px 2px 4px rgba(217, 217, 217, 1)',
                backgroundColor: 'rgba(97, 132, 157, 1)',
                display: 'flex',
                gap:"10px",
                width:'250px',
                height:'50px',
                padding:'4px',
            }}
        >
            {durations?.map((item, i) => (
                item?.value === duration ?
                    <div
                        key={i}
                        onClick={() => setDuration(item?.value)}
                        style={{
                            "borderRadius": "24px",
                            cursor: 'pointer',
                            "backgroundColor": "rgba(255, 255, 255, 1)",
                            "color": "rgba(2, 11, 32, 1)",
                            "fontFamily": "HelveticaNeueRegular",
                            "fontSize": "18px",
                            "fontWeight": "400",
                            "fontStyle": "Regular",
                            "letterSpacing": "0px",
                            "textAlign": "left",
                            display:'flex',
                            justifyContent: 'center',
                            alignItems:'center',
                            width:'100%',
                        }}
                    >
                        {item?.label}
                    </div>
                    :
                    <div
                        key={i}
                        onClick={() => setDuration(item?.value)}
                        style={{
                            cursor: 'pointer',
                            "color": "rgba(176, 194, 206, 1)",
                            "fontFamily": "HelveticaNeueRegular",
                            "fontSize": "18px",
                            "fontWeight": "400",
                            "fontStyle": "Regular",
                            "letterSpacing": "0px",
                            "textAlign": "left",
                            display:'flex',
                            justifyContent: 'center',
                            alignItems:'center',
                            width:'100%',
                        }}
                    >
                        {item?.label}
                    </div>
            ))}
        </div>
}
