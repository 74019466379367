import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { authSignUp, appData } from "../../../../core/axios/services";
import { Snackbar } from "@mui/material";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import "./SignUp.scss";
import star from "../../../../../src/star.svg";
import quote from "../../../../../src/quote.svg";
import { useDispatch, useSelector } from "react-redux";
import { addUser, addUserToken } from "../../../../redux/actions/user";
import brock from "../../../../../src/Brock_Headshot.jpg";

// Components
import TextInputLabel from "../../../components/Comon/TextInput/TextInput";
import Button from "../../../components/Comon/Button/Button";
import Logo from "../../../components/Comon/Logo/Logo";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const SignUp = ({ showGlobalSnackbar }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [logo, setLogo] = useState("/assets/logotm.png");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [testimonials, setTestimonials] = useState([]);
  const [testimonial, setTestimonial] = useState({});

  useEffect(() => {
    collectAppData().then((data) => {
      setTestimonials([
        {
          name: data.landingData.testimonial_1_name,
          job: data.landingData.testimonial_1_job,
          image: data.landingData.testimonial_1_image,
          text: data.landingData.testimonial_1_text,
        },
        {
          name: data.landingData.testimonial_2_name,
          job: data.landingData.testimonial_2_job,
          image: data.landingData.testimonial_2_image,
          text: data.landingData.testimonial_2_text,
        },
        {
          name: data.landingData.testimonial_3_name,
          job: data.landingData.testimonial_3_job,
          image: data.landingData.testimonial_3_image,
          text: data.landingData.testimonial_3_text,
        },
      ]);
      // setLogo(data.logo);
    });
  }, []);

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * testimonials.length);
    setTestimonial(testimonials[randomNumber]);
  }, [testimonials]);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const [searchParams] = useSearchParams();
  const plainId = searchParams.get('plainId');

  const signUp = async () => {
    localStorage.setItem("signUpEmail", email);

    try {
      return await authSignUp(email, password, passwordRepeat, user).then(
        (res) => {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
          localStorage.setItem("repeat", passwordRepeat);
          localStorage.setItem("userId", res.data.data.user.id);
          dispatch(addUser(res.data.data));
          dispatch(addUserToken(res.data.data));
          if(plainId){
            navigate("/otp?planId="+plainId);
          }else{
            navigate("/otp");
          }
        }
      );
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      showGlobalSnackbar(message);
    }
  };

  return (
    <div className="signUpWrapper">
      <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
        <Logo className="logo" src={logo} />
      </div>
      <div className="signUpContent">
        <div className="signUpForm">
          <h2>{t("welcomeSignUp")}</h2>
          <div className="formWrapper">
            <TextInputLabel
              type={"email"}
              label={i18n.t("signUpemailAddress")}
              value={email}
              setValue={setEmail}
              placeholder={t("enterYourEmail")}
            />
            <TextInputLabel
              type={"password"}
              label={i18n.t("password")}
              value={password}
              setValue={setPassword}
              placeholder={t("enterYourPassword")}
            />
            <div className="passwordRequire">
              <p>{t("passwordConsistOf")}</p>
              <ul>
                <li>{t("passwordMinimum")}</li>
                <li>{t("upperlLower")}</li>
                <li>{t("passowrdNumbers")}</li>
              </ul>
            </div>
            <div className="passwordRequireMob">
              <p>{t("mobilePasswordMust")}</p>
            </div>
            <TextInputLabel
              type={"password"}
              label={i18n.t("confirmPassword")}
              value={passwordRepeat}
              setValue={setPasswordRepeat}
              placeholder={t("enterYourPasswordConfirm")}
              required
            />
            <div className="privacyTermsLink">
              <p>
                {t("readPolicy")} <a href="/privacy">{t("privacypolicy")}</a>{" "}
                {t("and")} <a href="/terms">{t("terms")}</a>
              </p>
            </div>

            <div className="privacyTermsLinkMobile">
              <p>
                {t("readPolicy")} <br />
                <a href="/privacy">{t("privacypolicy")}</a> {t("and")}{" "}
                <a href="/terms">{t("terms")}</a>
              </p>
            </div>
            <div className="buttonSubmit" onClick={signUp}>
              <button>{t("createAccoutBtn")}</button>
            </div>
            <div className="haveAcc">
              <a href="/login">{t("alreadyHave")}</a>
            </div>
          </div>
        </div>
        <div className="signUpTestimonials">
          <h2>{t("customersReview")}</h2>
          <div className="testimonialContent">
            <img className="quote" src={quote} alt="quote" />

            <p>{t("signUpTestimonialDes")}</p>
            <span></span>
            <div className="testimonialName">
              {testimonial?.image ? <img src={brock} alt="brock" /> : null}
              <div>
                <div className={"testimonial-name"}>
                  {t("signUpTestimonialName")}
                </div>
                <div className={"testimonial-job"}>
                  {t("signUpTestimonialJob")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        message={snackMessage}
      />
    </div>
  );
};

export default SignUp;
