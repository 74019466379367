import React, { useState, useEffect } from "react";
import "./Details.scss";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@mui/material";
import Logo from "../../../components/Comon/Logo/Logo";
import {
  appData,
  authDetails,
  authDetailsAuth, axiosConfig,
} from "../../../../core/axios/services";
import {useNavigate, useSearchParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";

// Components
import TextInputLabel from "../../../components/Comon/TextInput/TextInput";
import { FileUploader } from "react-drag-drop-files";
import {API} from "../../../../constants/services/base";
import axios from "axios";

const Details = ({ showGlobalSnackbar, popup }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  console.log(user.user.id);

  const [logo, setLogo] = useState("/assets/logotm.png");
  const storeUser = localStorage.getItem("userId");
  const [userId, setUserId] = useState(storeUser ? storeUser : user.user.id);

  const storedEmail = localStorage.getItem("signUpEmail");
  const [email, setEmail] = useState(
    storedEmail ? storedEmail : localStorage.getItem("loginEmail")
  );
  // const [email, setEmail] = useState(localStorage.getItem("email"));
  const [password, setPassword] = useState(localStorage.getItem("password"));
  const [repeat, setRepeat] = useState(localStorage.getItem("repeat"));
  const [clinicName, setClinicName] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dataProtection, setDataProtection] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);

  const [loading, setLoading] = useState(false);


  const [searchParams] = useSearchParams();
  const planId = searchParams.get('planId');


  useEffect(() => {
    collectAppData().then((data) => {
      // setLogo(data.logo);
    });
  }, []);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const handleSubmit = async () => {
    if (clinicName === "") {
      showGlobalSnackbar("Clinic name is mandatory");
      return;
    } else if (address === "") {
      showGlobalSnackbar("Address is mandatory");
      return;
    } else if (firstName === "") {
      showGlobalSnackbar("First name of Admin is mandatory");
      return;
    } else if (lastName === "") {
      showGlobalSnackbar("Last name of Admin is mandatory");
      return;
    }
    try {
      setLoading(true);
      return await authDetails(
        userId,
        email,
        password,
        clinicName,
        firstName,
        lastName,
        address,
          dataProtection?.path
      ).then((res) => {
        if(planId){
          navigate("/pricing?planId=" + planId+'&autoSubmit=1');
        }else{
          navigate("/pricing");
        }
      });
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      // showGlobalSnackbar(message);
    } finally {
      setLoading(false); // Set loading to false after the request
    }
  };

  const handleSubmitPopup = async () => {
    if (clinicName === "") {
      showGlobalSnackbar("Clinic name is mandatory");
      return;
    } else if (address === "") {
      showGlobalSnackbar("Adress is mandatory");
      return;
    } else if (firstName === "") {
      showGlobalSnackbar("First name of Admin is mandatory");
      return;
    } else if (lastName === "") {
      showGlobalSnackbar("Last name of Admin is mandatory");
      return;
    }

    try {
      setLoading(true);
      return await authDetailsAuth(
        userId,
        email,
        password,
        clinicName,
        firstName,
        lastName,
        user,
        address,
          dataProtection?.path
      ).then((res) => {
        if(planId){
          navigate("/pricing?planId=" + planId+'&autoSubmit=1');
        }else{
          navigate("/pricing");
        }
      });
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      console.log(error);
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      // showGlobalSnackbar(message);
    } finally {
      setLoading(false); // Set loading to false after the request
    }
  };


  async function handleDataProtection(file) {
    const formData = new FormData();
    formData.append("file", file);

    await axios.post(`${API}/file`, formData, axiosConfig).then((res) => {
      setDataProtection(res.data.data);
    });
  }


  return (
    <div className="detailsWrapper">
      <Logo className="logo" src={logo} />
      <div className="otpWrapper">
        <div className="otpContent">
          <h2>{t("yourDetails")}</h2>
          <div className="fieldsWrapper">
            <TextInputLabel
              type={"text"}
              label={i18n.t("clinicName")}
              value={clinicName}
              setValue={setClinicName}
              placeholder="Enter your clinic name"
            />
            <TextInputLabel
              type={"text"}
              label="Address"
              value={address}
              setValue={setAddress}
              placeholder="(Street, City, Post Code, Country)"
            />
            <TextInputLabel
              type={"text"}
              label="First name"
              value={firstName}
              setValue={setFirstName}
              placeholder="Enter your first name"
            />

            <TextInputLabel
              type={"text"}
              label={i18n.t("adminLastName")}
              value={lastName}
              setValue={setLastName}
              placeholder="Enter your last name"
            />

            {
              dataProtection && <div>
                  <div style={{display: 'flex', gap: '10px'}}>
                    <img
                        src={"/assets/attachment.svg"}
                        alt="Attachment"
                    />
                    <a
                        target={'_blank'}
                        style={{
                          textDecoration: 'underline',
                          color: 'rgba(73, 73, 73, 1)',
                          fontSize: '14px',
                          fontWeight: '400',
                          letterSpacing: '0px',
                          textAlign: 'left',
                        }}
                        href={dataProtection?.url}
                    >{i18n.t("showUploadedDataProtection")}</a>

                    <div
                        style={{
                          cursor:'pointer',
                          color: 'rgba(73, 73, 73, 1)',
                          fontSize: '14px',
                          letterSpacing: '0px',
                          textAlign: 'left',
                          fontWeight: 'bold',
                        }}
                        onClick={() => setDataProtection(null)}
                    >REMOVE</div>

                  </div>


                </div>
            }
            {
                !dataProtection && <FileUploader
                    handleChange={handleDataProtection}
                    all
                    name="file"
                    type="file"
                    types={['pdf']}
                >
                  <div style={{marginTop: '24px', display: 'flex', gap: '10px', alignItems: 'flex-start'}}>
                  <img
                        src={"/assets/attachment.svg"}
                        alt="Attachment"
                    />
                    <div>
                      <p style={{textAlign: 'left'}}>{i18n.t("dataProcessingCreateAccount")}</p>
                      <p style={{marginTop: '18px', marginBottom:0}}>{i18n.t('supportedPdf')}</p>
                      <p style={{}}>{i18n.t('setupLater')}</p>

                    </div>
                  </div>
                </FileUploader>
            }


            {loading ? (
                <button className="verifySubmitLoader" disabled>
                  {t("startBtn")}{" "}
                  <Oval
                      height={20}
                      width={20}
                      color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#fff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </button>
            ) : (
              <div className="verifyButton">
                {!popup ? (
                  <button className="verifySubmit" onClick={handleSubmit}>
                    {t("startBtn")}
                  </button>
                ) : (
                  <button className="verifySubmit" onClick={handleSubmitPopup}>
                    {t("startBtn")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        message={snackMessage}
      />
    </div>
  );
};

export default Details;
