import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";
import PersonalizationButton from "./components/PersonalizationButton";
import React from "react";
import PersonalizationDivider from "./components/PersonalizationDivider";

export default function PersonalizationOpenEndedQuestion({user, questionOrder, getError,surveySettingsFormData,setSurveySettingsFormData,reviewLink,setReviewLink}) {
    const getQuestionData = (order) => surveySettingsFormData?.openEndedQuestions?.find(item => item.order === order);

    function handleUpdateQuestion(order, property, value) {
        let currentFormData = {...surveySettingsFormData};

        currentFormData.openEndedQuestions = currentFormData?.openEndedQuestions?.map((item) => {
            if (item?.order === order) {
                return {
                    ...item,
                    [property]: value,
                }
            }
            return item;
        });

        setSurveySettingsFormData(currentFormData);
    }
    function handleUpdateOptions(order, index, value) {
        let currentFormData = { ...surveySettingsFormData };

        currentFormData.openEndedQuestions = currentFormData?.openEndedQuestions?.map((item) => {
            if (item?.order === order) {
                let updatedOptions = [...(item.options || [])];
                updatedOptions[index] = value;
                return {
                    ...item,
                    options: updatedOptions,
                };
            }
            return item;
        });

        setSurveySettingsFormData(currentFormData);
    }


    function getErrorMessage(property){
        return getError('surveySettings',`openEndedQuestions.${questionOrder}.${property}`);
    }


    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px',
    }}>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap:'20px',
                opacity: getQuestionData(questionOrder)?.isActive ? 1 : 0.5,
            }}
        >
            {
                questionOrder === 0 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q1.png'} alt={'Welcome Screen'} />

                    <PersonalizationInput
                        title={'Title'}
                        value={surveySettingsFormData?.openEndedSubtitle?.toUpperCase()}
                        setValue={(value) => setSurveySettingsFormData({openEndedSubtitle: value?.toUpperCase()})}
                        errorMessage={getError('surveySettings','openEndedSubtitle')}
                    />

                    <PersonalizationTextarea
                        title={'Question/Statement'}
                        value={getQuestionData(0)?.title}
                        setValue={(value) => handleUpdateQuestion(0, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />
                    <PersonalizationTextarea
                        title={'Description'}
                        value={getQuestionData(0)?.description}
                        setValue={(value) => handleUpdateQuestion(0, 'description', value)}
                        errorMessage={getErrorMessage('description')}
                    />
                </>
            }
            {
                questionOrder === 1 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q2.png'} alt={'Welcome Screen'} />

                    <PersonalizationTextarea
                        title={'Question/Statement'}
                        value={getQuestionData(1)?.title}
                        setValue={(value) => handleUpdateQuestion(1, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />
                    <PersonalizationTextarea
                        title={'Description'}
                        value={getQuestionData(1)?.description}
                        setValue={(value) => handleUpdateQuestion(1, 'description', value)}
                        errorMessage={getErrorMessage('description')}
                    />
                </>
            }
            {
                questionOrder === 2 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q3.png'} alt={'Welcome Screen'} />

                    <PersonalizationTextarea
                        title={'Question'}
                        value={getQuestionData(2)?.title}
                        setValue={(value) => handleUpdateQuestion(2, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />
                    <PersonalizationInput
                        title={'Answer 1'}
                        value={getQuestionData(2)?.options?.[0]}
                        setValue={(value) => handleUpdateOptions(2, 0, value)}
                        errorMessage={getErrorMessage(', ')}

                    />
                    <PersonalizationInput
                        title={'Answer 2'}
                        value={getQuestionData(2)?.options?.[1]}
                        setValue={(value) => handleUpdateOptions(2, 1, value)}
                        errorMessage={getErrorMessage(', ')}

                    />
                    <PersonalizationInput
                        labelHelpText={'(Selecting this option reveals an input field for custom answers)'}
                        title={'Answer 3'}
                        value={getQuestionData(2)?.options?.[2]}
                        setValue={(value) => handleUpdateOptions(2, 2, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                </>
            }
            {
                questionOrder === 3 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q4.png'} alt={'Welcome Screen'} />


                    <PersonalizationTextarea
                        title={'Question'}
                        value={getQuestionData(3)?.title}
                        setValue={(value) => handleUpdateQuestion(3, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />

                    <PersonalizationInput
                        title={'Answer 1'}
                        value={getQuestionData(3)?.options?.[0]}
                        setValue={(value) => handleUpdateOptions(3, 0, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                    <PersonalizationInput
                        title={'Answer 2'}
                        value={getQuestionData(3)?.options?.[1]}
                        setValue={(value) => handleUpdateOptions(3, 1, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                    <PersonalizationInput
                        title={'Answer 3'}
                        value={getQuestionData(3)?.options?.[2]}
                        setValue={(value) => handleUpdateOptions(3, 2, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                    <PersonalizationInput
                        title={'Answer 4'}
                        value={getQuestionData(3)?.options?.[3]}
                        setValue={(value) => handleUpdateOptions(3, 3, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                    <PersonalizationInput
                        title={'Answer 5'}
                        value={getQuestionData(3)?.options?.[4]}
                        setValue={(value) => handleUpdateOptions(3, 4, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                    <PersonalizationInput
                        title={'Answer 6'}
                        value={getQuestionData(3)?.options?.[5]}
                        setValue={(value) => handleUpdateOptions(3, 5, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                    <PersonalizationInput
                        labelHelpText={'(Selecting this option reveals an input field for custom answers)'}
                        title={'Answer 7'}
                        value={getQuestionData(3)?.options?.[6]}
                        setValue={(value) => handleUpdateOptions(3, 6, value)}
                        errorMessage={getErrorMessage(', ')}
                    />
                </>
            }
            {
                questionOrder === 4 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q5.png'} alt={'Welcome Screen'} />

                    <PersonalizationTextarea
                        title={'Question'}
                        value={getQuestionData(4)?.title}
                        setValue={(value) => handleUpdateQuestion(4, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />

                    {
                        user?.user?.clinic?.businessPlan?.type !== 1 && (<>
                            <PersonalizationDivider/>

                            <PersonalizationInput
                                labelHelpText={'(if patients rate 5 stars, they will be prompted to leave you a google review)'}
                                title={'Google Review Link'}
                                value={reviewLink}
                                setValue={(value) => setReviewLink(value)}
                                errorMessage={getError(null,'reviewLink')}
                            />
                        </>)
                    }

                </>
            }
            {
                questionOrder === 5 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q6.png'} alt={'Welcome Screen'} />

                    <PersonalizationTextarea
                        labelHelpText={'(this question pops up if star rating is 4 and below)'}
                        title={'Question'}
                        value={getQuestionData(5)?.title}
                        setValue={(value) => handleUpdateQuestion(5, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />
                    {/*<PersonalizationTextarea*/}
                    {/*    title={'Description'}*/}
                    {/*    value={getQuestionData(5)?.description}*/}
                    {/*    setValue={(value) => handleUpdateQuestion(5, 'description', value)}*/}
                    {/*    errorMessage={getErrorMessage('description')}*/}
                    {/*/>*/}
                </>
            }
            {
                questionOrder === 6 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q7.png'} alt={'Welcome Screen'} />

                    <PersonalizationTextarea
                        title={'Question'}
                        value={getQuestionData(6)?.title}
                        setValue={(value) => handleUpdateQuestion(6, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />
                </>
            }
            {
                questionOrder === 7 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q8.png'} alt={'Welcome Screen'} />

                    <PersonalizationTextarea
                        title={'Question'}
                        value={getQuestionData(7)?.title}
                        setValue={(value) => handleUpdateQuestion(7, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />
                    <PersonalizationTextarea
                        title={'Description'}
                        value={getQuestionData(7)?.description}
                        setValue={(value) => handleUpdateQuestion(7, 'description', value)}
                        errorMessage={getErrorMessage('description')}
                    />
                </>
            }
            {
                questionOrder === 8 && <>
                    <PersonalizationCardImage src={'/assets/survey_personalization_screens/open_ended_q9.png'} alt={'Welcome Screen'} />

                    <PersonalizationTextarea
                        title={'Question'}
                        value={getQuestionData(8)?.title}
                        setValue={(value) => handleUpdateQuestion(8, 'title', value)}
                        errorMessage={getErrorMessage('title')}
                    />
                    {/*<PersonalizationTextarea*/}
                    {/*    title={'Description'}*/}
                    {/*    value={getQuestionData(8)?.description}*/}
                    {/*    setValue={(value) => handleUpdateQuestion(8, 'description', value)}*/}
                    {/*    errorMessage={getErrorMessage('description')}*/}
                    {/*/>*/}
                </>
            }
        </div>


        <div
            onClick={() => handleUpdateQuestion(questionOrder, 'isActive', !getQuestionData(questionOrder)?.isActive)}
        >
            {
                getQuestionData(questionOrder)?.isActive ?
                    <PersonalizationButton
                        text={'Disable'}
                        mode={"small"}
                        color={'white'}
                    />
                    :
                    <PersonalizationButton
                        text={'Enable'}
                        mode={"small"}
                        color={'white'}
                    />
            }
        </div>



    </div>
}
