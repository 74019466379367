import React, { useState, useEffect } from "react";
import "./Pricing.scss";
import { appData } from "../../../../core/axios/services";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { payment, paymentCheck } from "../../../../core/axios/services";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import { getAppData, resetPopUp } from "../../../../redux/actions/app";
import { useTranslation } from "react-i18next";
import hamburger from "../../../../../src/hamburger.svg";
import close from "../../../../../src/closeHamburger.svg";

// Components
import Logo from "../../../components/Comon/Logo/Logo";
import BusinessPlan from "../../../components/Admin Panel/BusinessPlan/BusinessPlan";
import ConfirmPopUp from "../../../components/Admin Panel/ConfirmPopUp/ConfirmPopUp";
import { showPopup } from "../../../../redux/actions/app";
import Header from "../../../components/Header";
import MobileHeader from "../../../components/MobileHeader";
import BusinessPlanDurationTabPicker from "../../../components/businessPlans/BusinessPlanDurationTabPicker";

const Pricing = ({ app, i18n, paid }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [plan, setPlan] = useState("");
  const [business, setBusiness] = useState([]);
  const [check, setCheck] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [duration, setDuration] = useState('monthly');

  useEffect(() => {
    collectAppData().then((data) => {
      // setLogo(data.logo);
      setBusiness(data.businessPlans);
    });
  }, []);

  useEffect(() => {
    appData().then((res) => {
      dispatch(getAppData(res.data.data));
    });
  }, []);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const [searchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const autoSubmit = searchParams.get('autoSubmit');

  useEffect(() => {
    if(planId && autoSubmit && business) {
      let planWithId = business?.find(item => parseInt(item.id) === parseInt(planId) );
      if(planWithId){
        updateBusinessPlan(planWithId)
      }
    }
  },[planId,autoSubmit,business]);

  const checkType = async () => {
    try {
      const res = await payment(plan, user);
      setCheck(res.data.url);

      if (res.data.url != null) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      navigate("/signup?plainId=" + plan?.id);
    }
  };

  const updateBusinessPlan = (plan) => {
    if (plan.type !== 3) {
      setPlan(plan);
    } else {
      window.location = "mailto:support@chiropracticoutcomes.com";
    }
  };

  useEffect(() => {
    if (plan) {
      checkType();
    }
  }, [plan]);

  const checkPayment = async () => {
    try {
      return paymentCheck(user).then((res) => {
        if (res.data.data.subscribed !== false) {
          navigate("/dashboard");
        }
      });
    } catch {
      alert("error");
    }
  };

  useEffect(() => {
    checkPayment();
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "errorPayment":
          navigate("/pricing");
          dispatch(resetPopUp());
          break;
        default:
          dispatch(resetPopUp());
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }

    if (paid === false) {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("paymentError"),
          confirmButton: i18n.t("paymentPopupButton"),
          type: "errorPayment",
        },
      };
      dispatch(showPopup(data));
      console.log(data);
    }
  }, [paid]);



  return (
    <div className="pricingWrapper">
      {/* Header */}
      <div className="container-fluid ">
        <div className="row  p-0 landing-section landing-section-one">
          <Header />

          {/* Mobile header */}
          <MobileHeader />
        </div>
        {/* End mobile header */}
      </div>
      {/* End Header */}

      <div className="pricingContent">
        <div className="content">
          <h2>{t("choosePlan")}</h2>
          <p>
            {t("pricingPlan")} <br />
            {t("pricingPlanBottom")}
          </p>

          <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "40px",
                marginTop: "40px",
              }}
          >
            <BusinessPlanDurationTabPicker
                setDuration={setDuration}
                duration={duration}
            />
          </div>


          <div className="pricingCard">
            {business?.filter(item => item?.duration === duration).map((businessPlan, index) => {
              return (
                <Box className={"otherBusinessPlanContainer"} key={index}>
                  {businessPlan.name === "Advanced" ? (
                    <div className="mostPopular">Most popular</div>
                  ) : (
                    <div className="hide">hide</div>
                  )}

                  <BusinessPlan
                    isSignup={true}
                    changePlan={(plan) => updateBusinessPlan(plan)}
                    app={app}
                    i18n={i18n}
                    isSelected={false}
                    businessPlan={businessPlan}
                    businessPlans={business}
                    isUpgradable={true}
                    mode={"other"}
                  />
                </Box>
              );
            })}
          </div>
        </div>
      </div>

      <div className="contactUs">
        <p>
          {t("contactQuesitons")}{" "}
          <a href="mailto: support@chiropracticoutcomes.com ">
            {t("contactQuesitonsUs")}
          </a>
          .
        </p>
      </div>
      {!paid ? <ConfirmPopUp app={app} /> : null}
    </div>
  );
};

export default Pricing;
