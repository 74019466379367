import Dropdown from "../Admin Panel/Dropdown/Dropdown";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Headline from "../Comon/Headline/Headline";

export default function SurveyLanguageAndTypePicker({languages, language, types, setLanguage, type, setType}) {
    const app = useSelector((state) => state.app);
    const user = useSelector((state) => state.user);
    const { i18n } = useTranslation();


    return <div>
        <div style={{marginBottom: '20px'}}>
            <Headline
                text={i18n.t("surveySettingsLanguageAndType")}
                type={"Headline-personalization"}
            />
        </div>

        {user?.user?.clinic?.businessPlan?.type !== 1 &&
            <Dropdown
                type={"language"}
                setSelectedOption={(opt) => setLanguage(opt)}
                selectedOption={language}
                options={languages}
                app={app}
                placeholder={i18n.t("surveyLanguage")}
            />
        }
        <Dropdown
            type={"settingType"}
            setSelectedOption={(opt) => setType(opt?.id)}
            selectedOption={type}
            options={types}
            app={app}
            placeholder={i18n.t("typeOfSurvey")}
        />
    </div>
}
