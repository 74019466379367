import React, { useEffect, useState } from "react";
import "./BusinessPlan.scss";
import { Box } from "@mui/material";
import Button from "../../Comon/Button/Button";
import { useSelector } from "react-redux";

const BusinessPlan = ({
  app,
  i18n,
                        businessPlan,
                        businessPlans,
  mode,
  isUpgradable,
  changePlan,
  isSelected,
  isSignup,
                        showSavings = true,
    showDuration = false,
                        activeDuration
}) => {
  const [className, setClassName] = useState("");
  const [headerStyle, setHeaderStyle] = useState({});
  const [color, setColor] = useState("");
  const [buttonText, setButtonText] = useState(i18n.t("getStarted"));
  const user = useSelector((state) => state.user);


  useEffect(() => {
    if (mode === "other") {
      setHeaderStyle({
        borderBottom: "0",
      });
    }

    let selectedClass = "";
    if (isSelected) {
      selectedClass = "business-plan-selected";
    }

    switch (businessPlan.id) {
      case 1:
      case 4:
        setClassName("BusinessPlan BasicPlan " + selectedClass);
        setColor("#61849D");
        break;
      case 2:
      case 5:
        setClassName("BusinessPlan AdvancedPlan " + selectedClass);
        setColor("#86B49C");
        break;
      case 3:
      case 6:
        setClassName("BusinessPlan EnterprisePlan " + selectedClass);
        setColor("#EBC263");
        break;
    }
  }, [app]);

  useEffect(() => {
    if (!isSignup) {
      if (businessPlan.id > user?.user?.clinic?.businessPlan?.id) {
        setButtonText(i18n.t("upgradePlan"));
      } else {
        setButtonText(i18n.t("downgrade"));
      }
    }
  }, [user, activeDuration]);

  let isEnterprise = businessPlan.type === 3;


  function calcualteSavings(){
    const monthlyPlan = businessPlans?.find(item => item.name === businessPlan?.name);
    return (monthlyPlan?.price * 12) - businessPlan?.price;
  }

  if (businessPlan) {
    return (
      <div className={className}>


        <div style={{width:'100%'}}>
          {showDuration && <div
              style={{
                "color": "rgba(97, 132, 157, 1)",
                "fontFamily": "HelveticaNeue-Italic",
                "fontSize": "16px",
                "fontWeight": "500",
                "fontStyle": "Medium Italic",
                "letterSpacing": "0px",
                "textAlign": "left",
                textTransform: "uppercase",
              }}
          >
            {businessPlan?.duration}
          </div>}
          {businessPlan?.duration === 'yearly' && showSavings &&
              <div
                  style={{
                    "color": "rgba(97, 132, 157, 1)",
                    "fontFamily": "HelveticaNeue-Italic",
                    "fontSize": "16px",
                    "fontWeight": "500",
                    "fontStyle": "Medium Italic",
                    "letterSpacing": "0px",
                    "textAlign": "left"
                  }}
              >
                Saves {calcualteSavings()} {businessPlan?.currency}
              </div>
          }
        </div>

        <Box className={"BusinessPlanHeader"} style={headerStyle}>
          <Box className={"BusinessPlanName"}>
            <div className={"BusinessPlanHeaderMaxi"}>{businessPlan.name}</div>
          </Box>

          <Box className={"BusinessPlanName"}>
            <div className={"BusinessPlanHeaderMaxi"}>
              {businessPlan.price} {businessPlan.currency}
            </div>
          </Box>
        </Box>
        <Box className={"BusinessPlanBody"}>
          {JSON.parse(businessPlan.description).map((description, item) => {
            return (
              <Box className={"businessPlanItem"} key={item}>
                <Box className={"businessPlanItemTickBox"}>
                  <img
                    className={"businessPlanItemTickBoxImg"}
                    src={"/assets/Icon_Checkmark2.svg"}
                    alt="businessPlanItemTickBoxImg"
                    width="17px"
                    height="12px"
                  />
                </Box>
                <Box className={"businessPlanItemText"}>{description} </Box>
              </Box>
            );
          })}
        </Box>

        {isEnterprise  ? (
          <Box
            className={"upgradeButtonContainer"}
            onClick={() => changePlan(businessPlan)}
          >
            <Button text={i18n.t("contactUs")} color={color} mode={"small"} />
          </Box>
        ) : null}

        {isUpgradable && !isEnterprise ? (
          <Box
            className={"upgradeButtonContainer"}
            onClick={() => changePlan(businessPlan)}
          >
            <Button text={buttonText} color={color} mode={"small"} />
          </Box>
        ) : null}

        {isSelected && !isEnterprise ? (
          <Box
            className={"upgradeButtonContainer"}
            onClick={() => changePlan(businessPlan)}
          >
            <Button
              text={i18n.t("manageSubscription")}
              color={color}
              // color={"transparent"}
              mode={"small"}
            />
          </Box>
        ) : null}
      </div>
    );
  }
};

export default BusinessPlan;
